<template>
  <BaseLoader v-if="!$store.getters.isLoaded(['organizations'])" />
  <div v-else>
    <div class="d-flex justify-content-between align-items-center mb-5">
      <h1 class="mr-4">Newsletters</h1>
      <button @click="createNewsletter" class="btn btn-primary ml-4">New Letter</button>
    </div>

    <table class="table table-hover">
      <thead>
        <tr>
          <th scope="col">Subject</th>

          <th scope="col">Created at</th>
          <th scope="col">Status</th>
        </tr>
      </thead>
      <tbody>
        <router-link
          v-for="newsletter in newsletters"
          :to="{ name: 'newsletter', params: { newsletterId: newsletter.id, newsletter } }"
          tag="tr"
          :key="newsletter.id"
        >
          <td>{{ newsletter.subject }}</td>
          <td>
            {{ moment(newsletter.createdAt.toDate()).format('DD.MM.YYYY, HH:mm') }}
          </td>
          <td>
            <span class="badge badge-warning" v-if="newsletter.status === 'draft'">Draft</span>
            <span class="badge badge-success" v-else-if="newsletter.status === 'sent'">Sent</span>
          </td>
        </router-link>
      </tbody>
    </table>
  </div>
</template>

<script>
import { db } from '@/shared/firebase'
import moment from 'moment'

export default {
  data() {
    return {
      newsletters: null
    }
  },
  created() {
    db.collection('newsletters')
      .orderBy('createdAt', 'desc')
      .onSnapshot(snapshot => {
        this.newsletters = []
        snapshot.docs.map(doc => {
          const newsletter = { ...doc.data(), id: doc.id }
          newsletter.recipients = doc.data().recipients?.map(recipient => {
            return { ...recipient, id: doc.id }
          })
          this.newsletters.push(newsletter)
        })
      })
  },
  methods: {
    createNewsletter: function() {
      db.collection('newsletters')
        .add({
          createdAt: new Date(),
          recipients: [],
          subject: '',
          body: '',
          status: 'draft'
        })
        .then(doc => {
          this.$router.push({ name: 'newsletter', params: { newsletterId: doc.id } })
        })
        .catch(err => {
          console.error(`Failed on new document creation. Message: ${err.message}`)
        })
    },
    moment: date => {
      return moment(date)
    }
  }
}
</script>
